<template>
	
</template>

<script>
	import Cookies from "js-cookie";
	import router from '../router'
	export default {
	    name: 'index',
	    data() {
	        return {
	           
	        }
	    },
		created() {
			var auth = Cookies.get('auth');
			var menu = JSON.parse(auth);
			router.push(menu[0].auth_url);
		}
	}
</script>
<style>
	
</style>
